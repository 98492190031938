const logotext = "SMHBK";
const meta = {
    title: "Saeed-Dev",
    description: "I’m Saeed Mohammed Frontend developer _ Full stack devloper",
};

const introdata = {
    title: "I’m Saeed Mohammed",
    animated: {
        first: "I love coding",
        second: "I’m Fullstack developer",
        third: "I’m Frontend developer",
    },
    description: "A Passionate Full Stack Software Developer Having Experience of Building Web Applications With Nextjs, React, Nodejs",
    your_img_url: "https://images.unsplash.com/photo-1514790193030-c89d266d5a9d",
};

const dataabout = {
    title: "abit about my self",
    aboutme: "I am a web developer specializing in building user interfaces using web technologies, I am obsessed with Typescript and Nextjs.",
};
const worktimeline = [{
        jobtitle: "Designer of week",
        where: "YAdfi",
        date: "2020",
    },
    {
        jobtitle: "Designer of week",
        where: "Jamalya",
        date: "2019",
    },
    {
        jobtitle: "Designer of week",
        where: "ALquds",
        date: "2019",
    },
];

const skills = [{
        name: "Javascript",
        lname: 'MongoDB',
    },
    {
        name:"HTML",
        lname: "CSS"
    },
    {
        name: "React",
        lname: 'NextJs',
    },
    {
        name: "Git + Github",
        lname: 'Strapi',
    },
    {
        name: "Express",
        lname: 'NodeJs',
    },
    {
        name: "Typescript",
        lname: "MaterialUi"
    }
];

const services = [{
        title: "UI & UX Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Mobile Apps",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
    {
        title: "Wordpress Design",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed at nisl euismod urna bibendum sollicitudin.",
    },
];

const dataportfolio = [{
        img: "https://i.ibb.co/wwQHxGx/Shopping-website.png",
        desctiption: "Ecommerce shopping website Next.js, Strapi, Material UI",
        link: "http://next-frontend-ecommerce-website.vercel.app",
        git: "https://github.com/saeedo-khan/next-frontend-ecommerce-website"
    },
    {
        img: "https://i.postimg.cc/cLtDdMpV/colorpalllwallpaper.png",
        desctiption: "Color pallete picker website with chroma js",
        link: "https://grand-dasik-d615b1.netlify.app",
        git: "https://github.com/saeedo-khan/colorPalette"
    },
    {
        img: "https://i.postimg.cc/6QtJkQcb/qwrrrrr.png",
        desctiption: "Expense Tracker React, Redux toolkit CRUD Application",
        link: "https://transcendent-hotteok-3a9980.netlify.app",
        git: "https://github.com/saeedo-khan/expense-tracker"
    }
];

const contactConfig = {
    YOUR_EMAIL: "smk1995m@gmail.com",
    YOUR_FONE: "+966 57 298 4168",
    description: "Discuss a project or just want to say hi? My Inbox is open for all.",
    YOUR_SERVICE_ID: "service_l09p7r9",
    YOUR_TEMPLATE_ID: "template_zd45bsx",
    YOUR_USER_ID: "6X-mvSPY12DGxfDNU",
};

const socialprofils = {
    github: "https://github.com/saeedo-khan",
    instagram: "https://www.instagram.com/saeedo0oo",
};
export {
    meta,
    dataabout,
    dataportfolio,
    worktimeline,
    skills,
    services,
    introdata,
    contactConfig,
    socialprofils,
    logotext,
};